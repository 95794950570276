
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";

import { Claim, ClaimCategory } from '@/types';
import * as ClaimCategoryService from '@/services/ClaimCategoryService';
import * as FactcheckService from '@/services/FactCheckService';

@Component
export default class ClaimForm extends Vue {
    @VModel() claim!: Claim;
    @Prop({ required: true }) prefilledFields!: Array<{ field: string, from: string }>


    search = '';

    selectedClaimCategories: ClaimCategory[] = [];

    claimCategories: ClaimCategory[] = [];
    claimants: string[] = [];

    politicalParties = [
        {
            name: 'Open Vld/MR: Alliance of Liberals and Democrats for Europe Party (ALDE)',
            value: 'Alliance of Liberals and Democrats for Europe Party (ALDE)'
        },
        {
            name: 'European Christian Political Movement (ECPM)',
            value: 'European Christian Political Movement (ECPM)'
        },
        {
            name: 'N-VA:European Conservatives and Reformists Party (ECR Party)',
            value: 'European Conservatives and Reformists Party (ECR Party)'
        },
        {
            name: 'European Democratic Party (EDP)',
            value: 'European Democratic Party (EDP)'
        },
        {
            name: 'Groen/Ecolo/PVDA/PTB: European Free Alliance (EFA)',
            value: 'European Free Alliance (EFA)'
        },
        {
            name: 'Groen/Ecolo: European Green Party (EFP)',
            value: 'European Green Party (EFP)'
        },
        {
            name: 'CD&V: European People’s Party (EPP)',
            value: 'European People’s Party (EPP)'
        },
        {
            name: 'Vlaams Belang: Identity and Democracy Party (ID Party)',
            value: 'Identity and Democracy Party (ID Party)'
        },
        {
            name: 'Niet-fractiegebonden leden',
            value: 'Non-Inscrits (NI)'
        },
        {
            name: 'PVDA/PTB: Party of the European Left (PEL)',
            value: 'Party of the European Left (PEL)'
        },
        {
            name: 'Vooruit/PS: Party of European Socialists (PES)',
            value: 'Party of European Socialists (PES)'
        },
        {
            name: 'Andere partij',
            value: 'Other party'
        },
    ];

    mediaFormats = [
        {
            code: 'image',
            name: 'Afbeelding'
        },
        {
            code: 'video',
            name: 'Video'
        },
        {
            code: 'audio',
            name: 'Audio'
        },
        {
            code: 'text',
            name: 'Tekst'
        },
        {
            code: 'other',
            name: 'Andere'

        }
    ]

    socialMediaPlatforms = [
        { name: 'X', code: 'x' },
        { name: 'Facebook', code: 'facebook' },
        { name: 'Instagram', code: 'instagram' },
        { name: 'TikTok', code: 'tiktok' },
        { name: 'YouTube', code: 'youtube' },
        { name: 'WhatsApp', code: 'whatsapp' },
        { name: 'Telegram', code: 'telegram' },
        { name: 'Signal', code: 'signal' },
        { name: 'LinkedIn', code: 'linkedin' },
        { name: 'Pinterest', code: 'pinterest' },
        { name: 'Snapchat', code: 'snapchat' },
        { name: 'Other', code: 'other' }
    ];

    countries = [
        {
            name: 'België',
            code: 'BE'
        },
        {
            name: 'Nederland',
            code: 'NL'
        },
        {
            name: 'Duitsland',
            code: 'DE'
        }
    ]

    async mounted() {
        this.claimCategories = await ClaimCategoryService.GetClaimCategories();
        this.claimants = await FactcheckService.GetClaimants();

        if (this.claim.ClaimCategories) {
            this.selectedClaimCategories = this.claim.ClaimCategories;
        }
    }

    @Watch('selectedClaimCategories', { deep: true })
    onSelectedClaimCategoriesChange() {
        this.$emit('categories', this.selectedClaimCategories);
    }

}

